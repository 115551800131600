<template>
  <div>
    <div class="ps-block--vendor-dashboard">
      <div class="ps-block__header">
        <h3>Payments</h3>
      </div>
      <div class="ps-block__content">
        <div class="ps-form--vendor-datetimepicker">
          <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="time-from">From</span>
                </div>
                <input class="form-control" aria-describedby="time-from" v-model="filters.from_date" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="time-form">To</span>
                </div>
                <input class="form-control" aria-describedby="time-to" v-model="filters.to_date" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
              <button type="button" class="ps-btn" @click="getPayments"><i class="icon-sync2"></i> Submit</button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Order</th>
                <th>Method</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in payments" :key="payment.id">
                <td>{{ payment.created_at|formatDate }}</td>
                <td>{{ payment.user.name }}</td>
                <td>{{ payment.order.order_number }}</td>
                <td><span class="text-primary">{{payment.method.name}}</span></td>
                <td>{{ payment.amount | toCurrency }}</td>
                <td>
                  <span v-if="payment.status.name === 'pending'" class="text-warning">{{ payment.status.label }} </span>
                  <span v-if="payment.status.name === 'paid'" class="text-success">{{ payment.status.label }} </span>
                  <span v-if="payment.status.name === 'deleted'" class="text-danger">{{ payment.status.label }} </span>
                </td>
                <td>
                  <router-link :to="{name: 'admin_payment_detail', params: {id: payment.id}}">
                    <i class="icon-eye" />
                  </router-link>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="(!meta || meta.total === 0) && !isLoading">
                <td colspan="7" class="text-center m-4 text-danger p-4">No payments available</td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="7" class="text-center m-4 text-danger">Loading payments...</td>
              </tr>
              <tr v-else>
                <td class="text-right" colspan="7">
                  <ul class="pagination text-right">
                    <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                      <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                    </li>
                    <li class="active"><a href="#">Page {{ pageNumber }}</a></li>
                    <!--                      <li><a href="#">3</a></li>-->
                    <li v-on:click="goToNextPage()" v-if="hasNextPage">
                      <a href="#">Next Page<i class="icon-chevron-right"></i></a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      filters: {
        from_date: null,
        to_date: null
      },
      payments:[],
      meta: null,
      isLoading: false,
      isDeleting: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1
    };
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      let url = 'payments';
      if (this.url) {
        url = this.url;
      }

      this.isLoading = true
      axios.get(url).then((response) => {
        this.payments = response.data.data
        this.meta = response.data.meta
        this.links = response.data.links;
        this.count = response.data.meta.total;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false
      });
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    },

    goToNextPage: function()
    {
      this.url = this.nextPage;
      if (this.url) {
        this.getPayments()
      }
      this.scrollTop();
    },

    goToPrevPage: function()
    {
      this.url = this.prevPage;
      if (this.url) {
        this.getPayments()
      }
      this.scrollTop();
    },

    handleNextPage(links)
    {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false
      }
    },

    handlePrevPage(links)
    {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false
      }
    }
  }
};
</script>